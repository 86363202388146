import React from "react";

import styles from "./PopUpSubscribe.module.scss";
import { FormattedMessage } from "react-intl";

function PopUpSubscribe({
    toggleClose,
    }) {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.linearBg}>
                    <div className={styles.closeBtn} onClick={toggleClose}>X</div>
                    <div className={styles.textWrap}>
                        <div className={styles.title}><FormattedMessage id="whiteNight_popupSubscribe_text" /></div>

            </div>
            </div>
            </div>
        </div>
    );        
}

export default PopUpSubscribe;