import React, { useState } from "react";

import styles from "./FaqBox.module.scss";
import iconArrow from "../../images/Images/icon_arrow.svg"

function FaqBox({
    title,
    text,
    text2,
    text3,
    }) {

    const [isActive, setActive] = useState(false);

    return (
        <div className={styles.boxRow} onClick={() => setActive(!isActive)}>
            <div className={isActive ? styles.inverseArrow : styles.defaultArrow}>
                <img src={iconArrow} className={styles.iconArrow} alt={""} ></img>
            </div>  

            <div className={styles.textBox}>
                <span className={styles.title}>{title}</span>
                {isActive 
                ?   <div className={styles.textPadding}>
                        <div className={styles.text}>{text}</div> 
                        {text2 ? <div className={styles.text}><br/>{text2}</div> : null }
                        {text3 ? <div className={styles.text}><br/>{text3}</div> : null } 
                    </div>
                : null }
            </div>
        </div>
    );        
}

export default FaqBox;