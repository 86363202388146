import React from "react";

function ButtonCheckOut(props) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        
            <g id="Capa_2" data-name="Capa 2">
                <g id="Assets_Settings" data-name="Assets Settings">
                    <g id="button_Select">
                        <polygon className={props.buttonOutline} points="7.55 1 1 7.55 1 31 24.45 31 31 24.45 31 1 7.55 1"/>
                        <polygon
                            className={props.buttonCenter}
                            points="10.37 6 6 10.37 6 26 21.63 26 26 21.63 26 6 10.37 6"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}
export default ButtonCheckOut

