import React from "react";

import styles from "./MediaBox.module.scss";

function MediaBox({
    reverseRow,
    contentImg,
    onClickHandler
    }) {

    return (
        <div className={reverseRow ? styles.boxReverse : styles.boxRow}>
            <div className={styles.imageBox}>
                <div style={{backgroundImage: `url(${contentImg})`}} className={styles.shadowImage}/>
                <div style={{backgroundImage: `url(${contentImg})`}} className={styles.mainImage} onClick={onClickHandler}/>
            </div>
            <div className={styles.imageBoxMobile}>
                <div style={{backgroundImage: `url(${contentImg})`}} className={styles.shadowImageMobile}/>
                <div style={{backgroundImage: `url(${contentImg})`}} className={styles.mainImageMobile}/>
            </div>
        </div>
    );        
}

export default MediaBox;