import React from "react";

import styles from "./MediaCarousel.module.scss";
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as CarouselPoint } from "../../images/Images/carousel_point.svg";

function MediaCarousel({
    imageSelector,
    image1,
    image2,
    image3,
    image4
}) {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
            <Carousel
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                emulateTouch={true}
                infiniteLoop={true}
                selectedItem={imageSelector}
                renderIndicator={(onClickHandler, isSelected) => {
                    if (isSelected) {
                        return (
                            <CarouselPoint
                            fill={"#0cf"}
                            className={styles.indicatorStyles}
                            />
                            );
                        }
                        return (
                            
                            <CarouselPoint
                            fill={"#707070"}
                            className={styles.indicatorStyles}
                                onClick={onClickHandler}
                                />
                                );
                            }}
                            >
                <div className={styles.carousel}>
                   <img className={styles.carouselImg} src={image1} alt="" />

                </div>
                <div className={styles.carousel}>
                    <img className={styles.carouselImg} src={image2} alt="" />

                </div>
                <div className={styles.carousel}>
                    <img className={styles.carouselImg} src={image3} alt="" />

                </div>
                <div className={styles.carousel}>
                    <img className={styles.carouselImg} src={image4} alt="" />

                </div>
            </Carousel>
            <div className={styles.carouselFrame} />
            </div>
        </div>
    );
}
export default MediaCarousel;