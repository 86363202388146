import React from "react";
import { Link } from 'react-router-dom';

import styles from "./NewsBox.module.scss";

function NewsBox({
    mainNew,
    title,
    subtitle,
    date,
    contentImg,
    link,
    description
}) {
    return (

        <div className={styles.main}>
            { mainNew
                ? <Link to={`/article/${link}`} className={styles.container}>
                    <div className={styles.boxWrap}>
                            <div className={styles.imageBox}
                                style={{ backgroundImage: `url(${contentImg})` }}
                            />
                        <div className={styles.textBox}>
                            <div className={styles.subtext}>{subtitle}</div>
                            <div className={styles.title}>{title}</div>
                            <div className={styles.text}>{description}</div>
                            <div className={styles.date}>{date}</div>
                        </div>
                    </div>
                </Link>

                : <Link to={`/article/${link}`} className={styles.wideContainer}>
                    <div className={styles.wideImageBoxWrap} >
                        <div className={styles.wideImageBox}
                            style={{ backgroundImage: `url(${contentImg})` }}>
                        </div>
                    </div>
                    <div className={styles.wideTextBox}>
                        <div className={styles.subtext}>{subtitle}</div>
                        <div className={styles.wideTitle}>{title}</div>
                        <div className={styles.wideText}>{description}</div>
                        <div className={styles.wideDate}>{date}</div>
                    </div>
                </Link>
            }
        </div>
    );
}

export default NewsBox;