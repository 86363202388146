import React from "react";

import styles from "./Impressum.module.scss";
import { FormattedMessage } from "react-intl";

function Impressum() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.logoBg}/>
                <div className={styles.container}>
                    <div className={styles.textWrap}>
                        <div className={styles.title}><FormattedMessage id="impressum_title"/></div>
                        <div className={styles.text}>
                        <div>
                            Patch Notes UG (haftungsbeschränkt) <br />
                            An Der Wuhlheide 2 <br />
                            12459, Berlin <br />
                            Info@patchnotes.com <br />
                            Telefon <br />
                            <br />
                            Geschäftsführerin:  Linda Post <br />
                            Registergericht: Amtsgericht Berlin-Charlottenburg <br />
                            Registernummer: HRG 187092 <br />
                            <br />
                            Sitz der Gesellschaft: Berlin <br />
                            Umsatzsteuer-ID: <br />
                            DE 312074586 <br />
                            <br />
                            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV <br />
                            Linda Post <br />
                            Anschrift wie oben <br />
                            </div>
                            <div>
                            <h4>Haftungsausschluss/Disclaimer</h4>

                            <strong>Haftung für Inhalte</strong>
                            <br />
                            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                            Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                            Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                            Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                            <br /><br />
                            <strong>Haftung für Links</strong>
                            <br />
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
                            keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                            oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                            der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                            verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                            entfernen.
                            <br /><br />
                            <strong>Urheberrecht</strong>
                            <br />
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
                            und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                            dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
                            die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                            gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                            werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                            <br/>
                            <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );        
}

export default Impressum;