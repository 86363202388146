import React from "react";
import styles from "./FirstScreen.module.scss";
import Opror from "../opror/Opror";
import CardLayer from "../cardLayer/CardLayer";
import GameContent from "../gameContent/GameContent";
import Heroes from "../heroes/Heroes";

function FirstScreen({ onChangeLanguage }) {

  return (
    <div className={styles.main} id={"Landingscreen"}>
      <Opror/>
      <CardLayer/>
      <GameContent/>
      <Heroes/>
      {/*<AssetView/> */}
    </div>
  );
}

export default FirstScreen;
