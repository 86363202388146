import React, { useState } from "react";

import styles from "./Card.module.scss";
import { ReactComponent as CardFrame} from "../../images/cards/frame1_cards.svg";

function Card({
    title,
    text, 
    color,
    cardFrontImg, 
    cardBackImg,
    }) {

    const [toggle, setToggle] = useState(true)
    const [img, setImg] = useState("")

    const handleClick = () => {
        setToggle(!toggle)
        if(toggle) {
            setImg(cardFrontImg)
        }
    }
    return (
        <div className={styles.main}>

            <div className={styles.cardTitleText}>{title}</div>
            <div className={styles.card} onClick={() => handleClick()}>
                <CardFrame
                    className={styles.cardFrame}
                    fill={color}/>
                <div className={toggle ? styles.imageBackEffect : styles.imageFrontEffect}>
                    <img
                        src={cardBackImg}
                        className={styles.cardBackImg}
                        alt={"card"}/>
                    <img 
                        src={img}
                        className={styles.cardFrontImg}
                        alt={"card"} />
                </div>
            </div>
            <div className={styles.cardSubText}>{text} </div>
        </div>
    );        
}

export default Card;