import React, { useEffect, useState, useRef } from "react";

import styles from "./Opror.module.scss";
import { FormattedMessage } from "react-intl";
import PopUp from "../../components/popUp/PopUp"
import ReactPlayer from 'react-player'

function Opror() {
    
    const ref = useRef(null);
    const [isSeen, setSeen] = useState(false);
    const [videoWidth, setVideoWidth] = useState(720);
    const [videoHeigth, setVideoHeigth] = useState(480);

    function ResizeVideoControl() {
        if (ref.current.offsetWidth < 720) {
            setVideoWidth(ref.current.offsetWidth);
            setVideoHeigth(ref.current.offsetWidth * 3 / 4 );
        }
        else {
            setVideoWidth(720);
            setVideoHeigth(480);
        }
    }

    useEffect(() => {
        ResizeVideoControl();
        function handleResize() {
            ResizeVideoControl();
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.oprorLogo}>
                    <div className={styles.videoPlayer} ref={ref}>
                        <div>
                            <ReactPlayer
                                url="https://youtu.be/8GIogqK1Xao"
                                controls={true}
                                width={videoWidth}
                                height={videoHeigth}
                            >
                            </ReactPlayer>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.textWrap}>
                        <div className={styles.title}>
                            <FormattedMessage id="opror_title" />
                        </div>
                        <div className={styles.text}>
                            <FormattedMessage id="opror_text" />
                        </div>

                        {/*<div className={styles.btnShadow}>
                            <div className={styles.downloadBtn} onClick={() => setSeen(true)}>
                                <span><FormattedMessage id="download"/></span>
                            </div>
                        </div>*/}

                    </div>
                </div>
            </div>
            {isSeen ?
                <PopUp
                    toggleClose={() => setSeen(!isSeen)}
                    /> :
                null}
        </div>
    );
}

export default Opror;
