import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import styles from "./NftMint.module.scss";
import bgCard1 from "../../images/founders/campaign_card.png"
import bgCard2 from "../../images/founders/campaign_card1.png"
import bgCard3 from "../../images/founders/campaign_card2.png"
import bgCard4 from "../../images/founders/campaign_card3.png"
import bgCard5 from "../../images/founders/campaign_card4.png"
import bgBorder from "../../images/founders/campaign_buttons.svg"
import bgBorder2 from "../../images/founders/campaign_buttons2.svg"
import twitter from "../../images/icons/icon_twitter_2.svg"
import discord from "../../images/icons/icon_discord_2.svg"
import ReCAPTCHA from "react-google-recaptcha";


function NftMint() {
  const { wallet } = useWallet();
  // const params = new URLSearchParams();

  const [searchParams] = useSearchParams()
  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showThanks, setShowThanks] = useState(false)
  const [showError, setShowError] = useState(null)
  const [captcha, setCaptcha] = useState(null);
  const [duplicatedWallet, setDuplicatedWallet] = useState(true);


  useEffect(() => {
    if (searchParams.get("social_type") === "twitter") {
      setShowPopup('twitter');
    } else if (searchParams.get("social_type") === "discord") {
      setShowPopup('discord');
    }
  }, [searchParams]);

  // const doTwitterRedirect = (client_id, redirect_uri, scope) => {
  //   const url = "https://twitter.com/i/oauth2/authorize";
  //   params.set("response_type", "code");
  //   params.set("client_id", client_id);
  //   params.set("redirect_uri", redirect_uri);
  //   params.set("scope", scope);
  //   params.set("state", "state");
  //   params.set("code_challenge", "challenge");
  //   params.set("code_challenge_method", "plain");
  //
  //   window.location = `${url}?${params.toString()}`;
  // }
  //
  // const onTwitterRedirect = () => {
  //   doTwitterRedirect('a1ZMaXJleTJrX2xkenR4bzM1SFk6MTpjaQ', 'https://g05fnzf0c1.execute-api.us-east-1.amazonaws.com/Prod/auth', 'tweet.write users.read tweet.read follows.write')
  // }
  const onMint = () => {
    setLoading(true);
    setShowError(false)
    let userWallet = wallet?.adapter?.publicKey.toBase58();
    const userToken = searchParams.get("token")
    axios.post(`https://g05fnzf0c1.execute-api.us-east-1.amazonaws.com/Prod/wallet`, {token: userToken, wallet: userWallet, captcha }).then(r => {
      setLoading(false);
      setShowThanks(true);
    }).catch(error => {
      if (error.response.status === 409) {
        setShowError("Error. Duplicated wallet");
        setDuplicatedWallet(false);
      } else {
        setShowError("An error occured, try again later")
      }
      setLoading(false);
    });
  }

  const onDiscordRedirect = () => {
    window.location.href = 'https://discord.gg/dMFf2KHjbX';
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <img alt="bgcard" src={bgCard1} className={styles.card1}/>
        <img alt="bgcard" src={bgCard2} className={styles.card2}/>
        <img alt="bgcard" src={bgCard3} className={styles.card3}/>
        <img alt="bgcard" src={bgCard4} className={styles.card4}/>
        <img alt="bgcard" src={bgCard5} className={styles.card5}/>

        <div className={styles.content}>

          <h3>PRE-LAUNCH CAMPAING FREE NFT</h3>
          <div className={styles.underline} />

          { !showPopup
          ? <React.Fragment>
              <h4>How to get Founder’s Certification and Rewards</h4>
              <h5>Become a Founder Member of Opror by following a few simple steps in our main social platforms and obtain 2 exclusive NFT Promo Cards you can later use for getting special benefits in the game… or safekeep them and sell them later.</h5>

              <h4>Mint is over!</h4>
              <div className={styles.optionsWrap}>

                <div className={styles.option}>
                  <div className={styles.optionImgWrap}>
                    <div className={styles.optionHeaderMobile}>Twitter steps - Click Here</div>
                    <img alt="twitter" src={twitter} className={styles.icon} />
                  </div>
                  <img alt="border" src={bgBorder2} className={styles.bgBorder} />
                  <div className={styles.optionList}>
                    <div className={styles.optionHeader}>Twitter steps - Mint is over!</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>Make sure you have logged into your Twitter Account and a Solana Wallet installed (we recommend using Solflare in your preferred web browser).</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>Click on this box in order to link your Twitter account.</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>Once linked, an automated re-tweet and a follow will be made in your behalf.</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>You'll be directed to the Wallet sync-page after the follow and re-tweet are done.</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>Link your Wallet, hit "Mint NFT" and you're done!  You'll receive your FREE NFT COLLECTIBLE automatically.</div>
                  </div>
                </div>

                <div className={styles.option} onClick={() => onDiscordRedirect()}>
                  <div className={styles.optionImgWrap}>
                    <div className={styles.optionHeaderMobile}>Discord steps - Click Here</div>
                    <img alt="discord" src={discord} className={styles.icon} />
                  </div>
                  <img alt="border" src={bgBorder} className={styles.bgBorder} />
                  <div className={styles.optionList}>
                    <div className={styles.optionHeader}>Discord steps - Mint is over!</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>Enter our Discord Community Server</div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>React to our Rules and Conditions of use</div>
                    <div className={styles.optionListItem}>
                      <div className={styles.listBullet}/>
                      <div>
                        Become an active member of our server by sending 1 message and sending <strong>mint</strong> to @MintBot .
                      </div>
                    </div>
                    <div className={styles.optionListItem}><div className={styles.listBullet}/>You’ll receive a DM from our MintBot with instructions on how to get your NFT intoy your eWallet.</div>
                  </div>
                </div>
              </div>

              <h5>Once you get both NFTS you’ll become a <span>Founder Member</span> and will receive extra rewards on each Season Update and occasional Airdrops.</h5>

              <div className={styles.disclaimer}>
                <div className={styles.optionHeader}>DISCLAIMER</div>
                <div className={styles.optionListItem}>NFTs obtained with this promotion are merely promotional and not usable as deployable tanks in-game.</div>
                <div className={styles.optionListItem}>Promotion is not mandatory to play the game.</div>
                <div className={styles.optionListItem}>In-game "Founder Title" can be obtained by connecting your Wallet when registering to the game once it's out. You can later "burn" the NFT COLLECTIBLE found in your Collection and claim the Title.</div>
                <div className={styles.optionListItem}>You could become a Founder later in the game if other user sells you their NFT COLLECTIBLE and you "burn" it to claim the Title.</div>
                {/* <div className={styles.optionListItem}>NFTs are randomly awarded from a pool of 19.650 cards. Legendary: 250, Epic: 400, Rare: 1000, Uncommon: 2000, Common: 16000</div> */}
              </div>
            </React.Fragment>

            : <React.Fragment>
                <div className={styles.enterWalletWrap}>
                  <div>
                    { showPopup === "twitter"
                      ? <div>Thanks for connecting with Twitter!</div>
                      : <div>Thanks for joining our Discord!</div>
                    }
                  </div>
                  <div className={styles.walletContainer}>
                    Login with you wallet
                    <div className={styles.wallet}><WalletMultiButton /></div>
                  </div>

                  { showError
                    ? <div>{showError}</div>
                    : null
                  }

                  { loading
                    ? <React.Fragment>
                        <div>Loading...</div>
                      </React.Fragment>
                    : <React.Fragment>
                      { showThanks
                        ? <div>Thanks! You will soon receive the NFT in your wallet.</div>
                        : <React.Fragment>
                              <ReCAPTCHA sitekey="6LcnqQckAAAAABf_WMuAdtijdktqTzI4yeJPTekZ" onChange={v => setCaptcha(v)} />
                            { wallet && captcha && duplicatedWallet
                            ? <React.Fragment>
                               <div className={styles.mintButton} onClick={() => onMint()}>Mint Nft!</div>
                               </React.Fragment>
                            : <div className={styles.mintButtonDisabled}>Mint Nft!</div>
                            }
                          </React.Fragment>
                        }
                      </React.Fragment>
                  }
                </div>
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  );
}

export default NftMint;
