import { React, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

import styles from "./WhiteNight.module.scss";
import { FormattedMessage } from "react-intl";
import ButtonCheckOut from "../../components/buttonCheckBox/ButtonCheckBox";
import PopUpSubscribe from "./popUpSubscribe/PopUpSubscribe";

function WhiteNight() {
    const [isActive, setActive] = useState(false);
    const [isSubscribe, setSubscribe] = useState(false);
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);

    const onSubmit = () => {
        if (isActive && email && name) {
            axios.post('https://kyuyt6swwk.execute-api.eu-central-1.amazonaws.com/prod/', {
                name: name,
                email: email
            })
                .then(() => {
                    setSubscribe(true);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }
    return (
        <div className={styles.main}>
            <div className={styles.mainBackground}>
            </div>
            <div className={styles.wrap}>
                <Link to={"/"} className={styles.header}></Link>

                <div className={styles.container}>

                    <div className={styles.contactText}>
                        <p><FormattedMessage id="whiteNight_text_p1" />
                            <b> <FormattedMessage id="whiteNight_text_p2" /> </b>
                            <FormattedMessage id="whiteNight_text_p3" />
                        </p>
                        <p><b><FormattedMessage id="whiteNight_text_p4" /></b></p>
                    </div>

                    <div className={styles.inputWrap}>
                        <div className={styles.inputContainer}>
                            <span> <FormattedMessage id="whiteNight_text_name" /> </span>
                            <input className={styles.email} type="text" placeholder="" onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className={styles.inputContainer}>
                            <span> <FormattedMessage id="whiteNight_text_email" /> </span>
                            <input className={styles.email} type="text" placeholder="" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className={styles.termConditions}>
                            <div className={styles.btnCheckBox} onClick={() => setActive(!isActive)}>
                                <ButtonCheckOut
                                    buttonOutline={styles.btnCheckBoxOutline}
                                    buttonCenter={isActive ? styles.btnCheckBoxCenterActive : null}
                                />
                            </div>
                            <p><FormattedMessage id="popup_text_p1" />
                                <Link to={"/impressum"}><FormattedMessage id="popup_text_blue" /></Link>
                                <FormattedMessage id="popup_text_p3" />
                            </p>
                        </div>

                        <div className={styles.subscribeBtn} onClick={() => onSubmit()}><FormattedMessage id="whiteNight_submit" /></div>
                    </div>

                    <div className={styles.submitBtn}>
                    </div>
                </div>
            </div>
            {isSubscribe ? <PopUpSubscribe
                toggleClose={() => setSubscribe(false)} /> : null}
        </div>
    );
}

export default WhiteNight;
