import React, { useEffect, useState } from "react";

import styles from "./News.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FormattedMessage } from "react-intl";
import underline from "../../images/Images/line_titles.svg";
import NewsBox from "../../components/newsBox/NewsBox";
import { ReactComponent as CarouselPoint } from "../../images/Images/carousel_point.svg";

import axios from 'axios';

function News() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        axios.get(`/articles/`).then(r => {
            setArticles(r.data);
        });
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.titleBg}><FormattedMessage id="news_titleBg" /></div>
                <div className={styles.skullBg}></div>
                <div className={styles.backgroundStyle}></div>
                <div className={styles.container}>
                    <div className={styles.mainNews}>
                        {articles.slice(0, 3).map((e, idx) => <NewsBox
                            mainNew={true}
                            title={e.Title}
                            subtitle={e.Subtitle}
                            description={e.Description}
                            date={e.Date}
                            contentImg={`${e.Thumbnail[0].url}`}
                            link={e.id}
                            key={idx}
                        />)}
                    </div>

                    <div className={styles.cardCarousel}>
                        <Carousel
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            emulateTouch={true}
                            infiniteLoop={true}
                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                if (isSelected) {
                                    return (
                                        <CarouselPoint
                                            fill={"#0cf"}
                                            className={styles.indicatorStyles}
                                            aria-label={`Selected: ${label} ${index + 1}`}
                                            title={`Selected: ${label} ${index + 1}`}
                                        />
                                    );
                                }
                                return (
                                    <CarouselPoint
                                        fill={"#707070"}
                                        className={styles.indicatorStyles}
                                        onClick={onClickHandler}
                                        onKeyDown={onClickHandler}
                                        value={index}
                                        key={index}
                                        role="button"
                                        tabIndex={0}
                                        title={`${label} ${index + 1}`}
                                        aria-label={`${label} ${index + 1}`}
                                    />
                                );
                            }}
                        >

                            {articles.slice(0, 3).map((e, idx) => <div className={styles.carousel}>
                                <NewsBox
                                    mainNew={true}
                                    title={e.Title}
                                    subtitle={e.Subtitle}
                                    date={e.Date}
                                    description={e.Description}
                                    contentImg={`${e.Thumbnail[0].url}`}
                                    link={e.id}
                                    key={idx}
                                /></div>
                            )}
                        </Carousel>
                    </div>

                    <div className={styles.title}> <FormattedMessage id="news_title" /></div>
                    <img src={underline} className={styles.underline} alt="" />

                    <div className={styles.wideNews}>
                        {articles.map((e, idx) => <NewsBox
                            mainNew={false}
                            title={e.Title}
                            subtitle={e.Subtitle}
                            date={e.Date}
                            description={e.Description}
                            contentImg={`${e.Thumbnail[0].url}`}
                            link={e.id}
                            key={idx}
                        />)}
                    </div>

                </div>
                <div className={styles.footerNav}>

                </div>
            </div>
        </div>
    );
}

export default News;
