import React from "react";

import styles from "./NotesPages.module.scss";

function NotesPages({ data }) {

    const insertBody = () => {
        return <p dangerouslySetInnerHTML={{ __html: data?.Body }}></p>
    }

    return (
        <div className={styles.main}>

            <div className={styles.imgContainer}>
                <div className={styles.frameImage}>
                    <div className={styles.imageBox}
                        style={{ backgroundImage: `url(${data?.Thumbnail[0].url})` }}
                    />
                </div>
            </div>
            {insertBody()}

        </div>
    );
}

export default NotesPages;
