import React from "react";

import styles from "./ErrorPage.module.scss";

function ErrorPage() {

  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <div className={styles.logoBg}/>
        <div className={styles.container}>
          <div className={styles.logoWrap}/>
          <div className={styles.textWrap}>
            <div className={styles.title}>Please try again later</div>
            <div className={styles.text}>
              <p>We are experiencing too much demand for Twitter minting.</p>
              <p>Remember you can join our Discord and mint there!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default ErrorPage;
