import styles from "./Furnace.module.scss";

import temporal from '../../images/temporal.png';

function Furnace() {

  return (
    <div className={styles.main}>
      <img src={temporal} alt="temp" />
      <div className={styles.comingSoon}>Coming soon</div>
    </div>
  );
}

export default Furnace;
