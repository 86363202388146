import React from "react";
// import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import styles from "./Footer.module.scss";
// import iconLanguage from "../../images/Images/icon_language.svg";
import { FormattedMessage } from "react-intl";
// import iconArrow from "../../images/Images/icon_arrow.svg";
// import useOutsideClick from "../outsideClick/useOutsideClick";

function Footer({ onChangeLanguage }) {

//  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
//  const [language, setLanguage] = useState("English");
  //const appLang = window.localStorage.getItem("language");
  //const ref = useRef();

  // useOutsideClick(ref, () => {
  //  setShowLanguageSelector(false);
  //});

  /*useEffect( () => {
    setLanguage(checkLang(appLang));
    }, [])

  const checkLang = (e) => {
  switch(e) {
    case "en":
      return "English"
      break;
    case "es":
      return "Spanish"
      break;
    case "de":
      return "German"
      break;
    default:
      return "English"
    }
  }
  */
  //const chooseLanguage = (e) => {
  //  onChangeLanguage(e);
  //  setShowLanguageSelector(false);
  //}

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.backgorundImg}>
                </div>
                <div className={styles.navWrap}>
                    <div className={styles.column}>
                        <Link to={"/aboutUs"} className={styles.squarelessBtn}>
                            <span><FormattedMessage id="aboutUs"/></span>
                        </Link>
                        <Link to={"/impressum"} className={styles.squarelessBtn}>
                            <span><FormattedMessage id="impressum"/></span>
                        </Link>
                    </div>
                    <div className={styles.column}>
                        <Link to={"/datenschutz"} className={styles.squarelessBtn}>
                            <span><FormattedMessage id="datenschutz"/></span>
                        </Link>
                        <Link to={"/faq"} className={styles.squarelessBtn}>
                            <span><FormattedMessage id="faq"/></span>
                        </Link>
                    </div>

                    {/*<div className={styles.column}>
                        <div className={showLanguageSelector ? styles.dropboxActive : styles.dropbox}
                          onClick={() => setShowLanguageSelector(!showLanguageSelector)}
                          ref={ref}
                        >
                            <img src={iconLanguage} className={styles.iconLanguage} alt=""/>
                            <div>{language}</div>
                            <img src={iconArrow} className={styles.iconArrow} alt=""/>

                        </div>
                        { showLanguageSelector
                          ? <div className={styles.languageWrap}>
                              <div className={styles.languageSelector} onClick={() => chooseLanguage("en")}>English</div>
                              <div className={styles.languageSelector} onClick={() => chooseLanguage("es")}>Spanish</div>
                              <div className={styles.languageSelector} onClick={() => chooseLanguage("de")}>German</div>
                            </div>
                          : null
                        }
                    </div>
                      */}

                </div>
            </div>
        </div>
    );
}

export default Footer;
