import React from "react";

import styles from "./Datenschutz.module.scss";
import { FormattedMessage } from "react-intl";

function Datenschutz() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.logoBg}/>
                <div className={styles.container}>
                    <div className={styles.textWrap}>
                        <div className={styles.title}><FormattedMessage id="datenschutz_title"/></div>
                        <div className={styles.text}>
                            <h3>Datenschutzerklärung</h3>
                            <div>
                                <strong>§ 1 Information über die Erhebung personenbezogener Daten</strong>
                                <br />
                                Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung
                                unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich
                                beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
                                <br />
                                Verantwortlicher gem. Art.4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist
                                <br />
                                Patch Notes UG (haftungsb.) <br />
                                Linda Post <br />
                                An Der Wuhlheide 2, 12459, Berlin <br />
                                Email: info@patchnotes.com <br />
                                (siehe unser Impressum) <br />
                                <br />
                                `Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden
                                die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre
                                Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem
                                Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
                                erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche
                                Aufbewahrungspflichten bestehen.`
                                <br /><br />
                                `Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister
                                zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie
                                untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir
                                auch die festgelegten Kriterien der Speicherdauer.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 2 Ihre Rechte</strong>
                                <br />
                                `Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden
                                personenbezogenen Daten:`<br />
                                    –Recht auf Auskunft, <br />
                                    –Recht auf Berichtigung oder Löschung, <br />
                                    –Recht auf Einschränkung der Verarbeitung, <br />
                                    –Recht auf Widerspruch gegen die Verarbeitung, <br />
                                    –Recht auf Datenübertragbarkeit. <br />
                                <br />
                                `Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über
                                die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website</strong>
                                <br />
                                `Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht
                                registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur
                                die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn
                                Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns
                                technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität
                                und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):`
                                <br />
                                    –IP-Adresse <br />
                                    –Datum und Uhrzeit der Anfrage <br />
                                    –Zeitzonendifferenz zur Greenwich Mean Time (GMT) <br />
                                    –Inhalt der Anforderung (konkrete Seite) <br />
                                    –Zugriffsstatus/HTTP-Statuscode <br />
                                    –jeweils übertragene Datenmenge <br />
                                    –Website, von der die Anforderung kommt <br />
                                    –Browser <br />
                                    –Betriebssystem und dessen Oberfläche <br />
                                    –Sprache und Version der Browsersoftware. <br />
                                <br />
                                `Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website
                                Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien,
                                die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden
                                und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte
                                Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren
                                Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher
                                und effektiver zu machen.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 4 Einsatz von Cookies:</strong>
                                <br />
                                a) `Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise
                                im Folgenden erläutert werden:` <br />
                                    –Transiente Cookies (dazu b) <br />
                                    –Persistente Cookies (dazu c). <br /><br />
                                b) `Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen.
                                Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID,
                                mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen
                                lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website
                                zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den
                                Browser schließen. `<br /><br />
                                c) `Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die
                                sich je nach Cookie unterscheiden kann. Sie können die Cookies in den
                                Sicherheitseinstellungen Ihres Browsers jederzeit löschen. <br /><br />
                                d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B.
                                die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf
                                hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 5 Einsatz von Google Analytics</strong>
                                <br />
                                a) `Diese Website ben utzt Google Analytics, einen Webanalysedienst der Google Inc.
                                („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem
                                Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                                ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
                                Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                                gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird
                                Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
                                oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor
                                gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
                                USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google
                                diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
                                die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der
                                Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.`
                                <br /><br />
                                b) `Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
                                mit anderen Daten von Google zusammengeführt.`
                                <br /><br />
                                c) `Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                                Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
                                können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
                                Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                                Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                                verfügbare Browser-Plug-in herunterladen und installieren:
                                http://tools.google.com/dlpage/gaoptout?hl=de.`
                                <br /><br />
                                d) `Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“.
                                Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann
                                damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug
                                zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit
                                umgehend gelöscht.`
                                <br /><br />
                                e) `Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig
                                verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern
                                und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen
                                personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy
                                Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework. Rechtsgrundlage für die
                                Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.`
                                <br /><br />
                                f) `Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House,
                                Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen:
                                http://www.google.com/analytics/terms/de.html, Übersicht zum
                                Datenschutz: http://www.google.com/intl/de/analytics/ learn/privacy.html, sowie die
                                Datenschutzerklärung: http://www.google.de/intl/de/policies/privacy.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 6 Einsatz von Social-Media-Plug-ins</strong>
                                <br />
                                a) `Wir setzen derzeit folgende Social-Media-Plug-ins ein: Facebook, Glassdoor, Twitter,
                                Xing, Vimeo, LinkedIn.Wir nutzen dabei die sog. Zwei-Klick-Lösung.Das heißt, wenn
                                Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten
                                an die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins erkennen Sie über
                                die Markierung auf dem Kasten über seinen Anfangsbuchstaben oder das Logo. Wir eröffnen
                                Ihnen die Möglichkeit, über den Button direkt mit dem Anbieter des Plug-ins zu
                                kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren,
                                erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Website unseres
                                Online-Angebots aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten
                                Daten übermittelt. Im Fall von Facebook und Xing wird nach Angaben der jeweiligen Anbieter
                                in Deutschland die IP-Adresse sofort nach Erhebung anonymisiert. Durch die
                                Aktivierung des Plug-ins werden also personenbezogene Daten von Ihnen an den jeweiligen
                                Plug-in-Anbieter übermittelt und dort (bei US-amerikanischen Anbietern in den USA)
                                gespeichert. Da der Plug-in-Anbieter die Datenerhebung insbesondere über Cookies vornimmt,
                                empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die
                                Sicherheitseinstellungen Ihres Browsers alle Cookies zu löschen.`
                                <br /><br />
                                b) `Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge,
                                noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die
                                Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter
                                liegen uns keine Informationen vor.`
                                <br /><br />
                                c) `Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und
                                nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung
                                seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte
                                Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen
                                Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein
                                Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung
                                dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bietet wir
                                Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren,
                                so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten
                                können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art.6 Abs.1 S.1 lit. f DS-GVO.`
                                <br /><br />
                                d) `Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem
                                Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter
                                eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter
                                bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z. B. die
                                Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem
                                Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich
                                nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor
                                Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem
                                Plug-in-Anbieter vermeiden können.`
                                <br /><br />
                                e) `Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung
                                durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten
                                Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen
                                zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
                                Privatsphäre. `<br /><br />
                                f) `Adressen der jeweiligen Plug-in-Anbieter und URL mit deren Datenschutzhinweisen: <br />
                                    -Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA;
                                    http://www.facebook.com/policy.php; weitere Informationen zur Datenerhebung:
                                    http://www.facebook.com /help/186325668085084,
                                    http://www.facebook.com/about/privacy/your-info-on-other#applications sowie
                                    http://www.facebook.com/about/privacy/your-info#everyoneinfo. Facebook hat sich dem
                                    EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.`
                                    <br />
                                    -`Glassdoor Inc., 100 Shoreline Highway, Mill Valley, California, USA;
                                    https://www.glassdoor.de/about/privacy.htm`
                                    <br />
                                    -`Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA;
                                    https://twitter.com/privacy. Twitter hat sich dem EU-US-Privacy-Shield unterworfen,
                                    https://www.privacyshield.gov/EU-US-Framework.`
                                    <br />
                                    -`Xing AG, Gänsemarkt 43, 20354 Hamburg, DE; http://www.xing.com/privacy.`
                                    <br />
                                    -`Vimeo Inc 555 West 18th Street New York, New York 10011, USA; https://vimeo.com/privacy`
                                    <br />
                                    -`LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA;
                                    http://www.linkedin.com/legal/privacy-policy. LinkedIn hat sich dem EU-US-Privacy-Shield
                                    unterworfen, https://www.privacyshield.gov/EU-US-Framework.`
                                    <br /><br />
                                </div>

                                <div>
                                <strong>§ 7 Einbindung von YouTube-Videos</strong><br />
                                a) `Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf http://www.YouTube.com gespeichert sind und von unserer Website aus direkt abspielbar sind. Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die in Absatz 2 genannten Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.`
                                <br /><br />
                                b) `Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.`
                                <br /><br />
                                c) `Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: https://www.google.de/intl/de/policies/privacy. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 8 Einsatz von Google AdSense</strong><br />
                                (1)`Diese Website verwendet den Online-Werbedienst Google AdSense, durch den Ihnen auf Ihre Interessen ausgelegte Werbung präsentiert werden kann. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse sein könnte, um unsere Website für Sie interessanter zu gestalten. Hierzu werden statistische Informationen über Sie erfasst, die durch unsere Werbepartner verarbeitet werden. Diese Werbeanzeigen sind über den Hinweis „Google-Anzeigen“ in der jeweiligen Anzeige erkennbar.`
                                <br />    <br />
                                (2) `Durch den Besuch unserer Website erhält Google die Information, dass Sie unsere Website aufgerufen haben. Dazu nutzt Google einen Web Beacon, um einen Cookie auf Ihrem Rechner zu setzen. Es werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Wir haben weder Einfluss auf die erhobenen Daten, noch ist uns der volle Umfang der Datenerhebung und die Speicherdauer bekannt. Ihre Daten werden in die USA übertragen und dort ausgewertet. Wenn Sie mit Ihrem Google-Account eingeloggt sind, können Ihre Daten diesem direkt zugeordnet werden. Wenn Sie die Zuordnung mit Ihrem Google-Profil nicht wünschen, müssen Sie sich ausloggen. Es ist möglich, dass diese Daten an Vertragspartner von Google an Dritte und Behörden weitergegeben werden. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
                                Diese Website hat auch Google AdSense-Anzeigen von Drittanbietern aktiviert. Die vorgenannten Daten können an diese Drittanbieter (benannt unter https://support.google.com/dfp_sb /answer/94149) übertragen werden.`
                                <br />    <br />
                                (3) `Sie können die Installation der Cookies von Google AdSense auf verschiedene Weise verhindern: a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten; b) durch Deaktivierung der interessenbezogenen Anzeigen bei Google über den Link http://www.google.de/ads/preferences, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link http://www.aboutads.info/choices, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link http://www.google.com/settings/ads/plugin.Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.`
                                <br />    <br />
                                (4) "Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung sowie weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre erhalten Sie bei: Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA; Datenschutzbedingungen für Werbung: http://www.google.de/intl/de/policies/ technologies/ads. Google hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework."
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 9 Amazon Partnerprogramm</strong> <br />
                                (1) "Patch Notes UG ist Teilnehmer des Partnerprogramms von Amazon Europe S. à. r. l. und Partner des Werbeprogramms, das zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels dessen durch die Platzierung von Werbeanzeigen und Links zu amazon.de Werbekostenerstattungen verdient werden können. Wir verfolgen mit dem Programm das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist und unsere Website für unsere Nutzer interessanter wird."
                                <br /><br />
                                (2) `Für die Bereitstellung der Werbeanzeigen werden statistische Informationen über Sie erfasst, die durch unsere Werbepartner verarbeitet werden. Durch den Besuch auf der Website erhält Amazon die Information, dass Sie die entsprechende Seite unserer Website aufgerufen haben. Dazu ermittelt Amazon über Web Beacons Ihren Bedarf und setzt ggf. ein Cookie auf Ihrem Rechner. Es werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Wir haben weder Einfluss auf die erhobenen Daten, noch ist uns der volle Umfang der Datenerhebung und die Speicherdauer bekannt. Wenn Sie bei Amazon eingeloggt sind, können Ihre Daten direkt Ihrem dortigen Konto zugeordnet werden. Wenn Sie die Zuordnung mit Ihrem Amazon-Profil nicht wünschen, müssen Sie sich ausloggen. Es ist möglich, dass Ihre Daten an Vertragspartner von Amazon und Behörden weitergegeben werden. Wir haben weder Einfluss auf die erhobenen Daten, noch ist uns der volle Umfang der Datenerhebung bekannt. Die Daten werden in die USA übertragen und dort ausgewertet. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1S. 1 lit. f DS-GVO.`
                                <br /><br />
                                (3) "Sie können die Installation der Cookies des Amazon Partnerprogramms auf verschiedene Weise verhindern: a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten; b) durch Deaktivierung der interessenbezogenen Anzeigen bei Amazon über den Link http://www.amazon.de/gp/dra/info; c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link http://www.aboutads.info/choices, wobei diese Einstellungen gelöscht werden, wenn Sie Ihre Cookies löschen. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieses Angebotes vollumfänglich nutzen können".
                                <br /><br />
                                (4) "Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung sowie weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre erhalten Sie über die oben genannte Datenschutzerklärung hinaus auch bei: Amazon EU S.à.r.l, die Amazon Services Europe S.à.r. l. und die Amazon Media EU S.à.r. l., alle drei ansässig 5, Rue Plaetis, L–2338 Luxemburg; E-Mail: ad-feedback@amazon.de. Amazon hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework. Weitere Informationen zur Datennutzung durch Amazon erhalten Sie in der Datenschutzerklärung des Unternehmens: http://www.amazon.de/gp/help/customer /display.html /ref=footer_privacy?ie=UTF8&nodeId=3312401 sowie unter: http://www.amazon.de/gp /BIT/InternetBasedAds."
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 10 Einsatz von Google Adwords Conversion</strong>
                                <br />
                                (1) `Wir nutzen das Angebot von Google Adwords, um mit Hilfe von Werbemitteln (sogenannten Google Adwords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir können in Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, unsere Website für Sie interessanter zu gestalten und eine faire Berechnung von Werbe-Kosten zu erreichen.`<br /><br />
                                (2) `Diese Werbemittel werden durch Google über sogenannte „Ad Server“ ausgeliefert. Dazu nutzen wir Ad Server Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden können. Sofern Sie über eine Google-Anzeige auf unsere Website gelangen, wird von Google Adwords ein Cookie in ihrem PC gespeichert. Diese Cookies verlieren in der Regel nach 30 Tagen ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in der Regel als Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency), letzte Impression (relevant für Post-View-Conversions) sowie Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen werden möchte) gespeichert.`
                                <br /><br />
                                (3) `Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite eines Adwords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jedem Adwords-Kunden wird ein anderes Cookie zugeordnet. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir selbst erheben und verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google lediglich statistische Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer nicht anhand dieser Informationen identifizieren.`
                                <br /><br />
                                (4) `Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von AdWords Conversion erhält Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert.`
                                <br /><br />
                                (5) `Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern: a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten; b) durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, https://www.google.de/settings/ads, wobei diese Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen; c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link http://www.aboutads.info/choices, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link http://www.google.com/settings/ads/plugin. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.`
                                <br /><br />
                                (6) `Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Weitere Informationen zum Datenschutz bei Google finden Sie hier: http://www.google.com/intl/de/policies/privacy und https://services.google.com/sitestats/de.html. Alternativ können Sie die Webseite der Network Advertising Initiative (NAI) unter http://www.networkadvertising.org besuchen. Google hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 11 Remarketing</strong>
                                <br />
                                `Neben Adwords Conversion nutzen wir die Anwendung Google Remarketing. Hierbei handelt es sich um ein Verfahren, mit dem wir Sie erneut ansprechen möchten. Durch diese Anwendung können Ihnen nach Besuch unserer Website bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen eingeblendet werden. Dies erfolgt mittels in Ihrem Browser gespeicherter Cookies, über die Ihr Nutzungsverhalten bei Besuch verschiedener Websites durch Google erfasst und ausgewertet wird. So kann von Google Ihr vorheriger Besuch unserer Website festgestellt werden. Eine Zusammenführung der im Rahmen des Remarketings erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von Google gespeichert werden, findet durch Google laut eigenen Aussagen nicht statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung eingesetzt.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 12 DoubleClick by Google</strong>
                                <br />
                                (1) `Diese Webseite nutzt weiterhin das Online Marketing Tool DoubleClick by Google. DoubleClick setzt Cookies ein, um für die Nutzer relevante Anzeigen zu schalten, die Berichte zur Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer die gleichen Anzeigen mehrmals sieht. Über eine Cookie-ID erfasst Google, welche Anzeigen in welchem Browser geschaltet werden und kann so verhindern, dass diese mehrfach angezeigt werden. Darüber hinaus kann DoubleClick mithilfe von Cookie-IDs sog. Conversions erfassen, die Bezug zu Anzeigenanfragen haben. Das ist etwa der Fall, wenn ein Nutzer eine DoubleClick-Anzeige sieht und später mit demselben Browser die Website des Werbetreibenden aufruft und dort etwas kauft. Laut Google enthalten DoubleClick-Cookies keine personenbezogenen Informationen.`
                                <br /><br />
                                (2) `Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von DoubleClick erhält Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert.`
                                <br /><br />
                                (3) `Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern: a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten; b) durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, https://www.google.de/settings/ads, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link http://www.aboutads.info/choices, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link http://www.google.com/settings/ads/plugin. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.`
                                <br /><br />
                                (4) `Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Weitere Informationen zu DoubleClick by Google erhalten Sie unter https://www.google.de/doubleclick und http://support.google.com/adsense /answer/2839090, sowie zum Datenschutz bei Google allgemein: https://www.google. de/intl/de/policies/privacy. Alternativ können Sie die Webseite der Network Advertising Initiative (NAI) unter http://www.networkadvertising.org besuchen. Google hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.`
                                <br /><br />
                                </div>

                                <div>
                                <strong>§ 13 Facebook Custom Audiences</strong>
                                <br />
                                (1) `Weiterhin verwendet die Website die Remarketing-Funktion „Custom Audiences“ der Facebook Inc. („Facebook“). Dadurch können Nutzern der Website im Rahmen des Besuchs des sozialen Netzwerkes Facebook oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Facebook-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.`
                                <br /><br />
                                (2) `Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Facebook erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von Facebook Custom Audiences erhält Facebook die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Facebook registriert sind, kann Facebook den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert. `
                                <br /><br />
                                (3) `Die Deaktivierung der Funktion „Facebook Custom Audiences“ ist hier und für eingeloggte Nutzer unter https://www.facebook.com/settings/?tab=ads#_möglich.`
                                <br /><br />
                                (4) `Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Weitere Informationen zur Datenverarbeitung durch Facebook erhalten Sie unter https://www.facebook.com/about/privacy.`
                                <br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Datenschutz;
