import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./CardLayer.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import commonCard from "../../images/cards/Common_Pack.png";
import rareCard from "../../images/cards/Rare_Pack.png";
import epicCard from "../../images/cards/Epic_Pack.png";
import legendaryCard from "../../images/cards/Legendary_Pack.png";
import Card from "../../components/card/Card";
import lineTitle from "../../images/Images/line_titles.svg"
import { ReactComponent as CarouselPoint } from "../../images/Images/carousel_point.svg";

import gearCoolantCanister from "../../images/cards/Gear_Coolant_Canister.png";
import gearLifeSupportSystems from "../../images/cards/Gear_Life_Support_Systems.png";
import unitFlytrap from "../../images/cards/Unit_Flytrap.png";
import unitLancer from "../../images/cards/Unit_Lancer.png";

import gearLongRangeComms from "../../images/cards/Gear_Long_Range_Comms.png";
import techcardLastCommands from "../../images/cards/Techcard_Last_Commands.png";
import gearShockShells from "../../images/cards/Gear_Shock_Shells.png";
import unitCrucible from "../../images/cards/Unit_Crucible.png";

import gearFirewall from "../../images/cards/Gear_Firewall.png";
import gearPowerCell from "../../images/cards/Gear_Power_Cell.png";
import techcardSoreLoser from "../../images/cards/Techcard_Sore_Loser.png";
import unitCloud from "../../images/cards/Unit_Cloud.png";

import unitRogueEye from "../../images/cards/Unit_Rogue_Eye.png";
import techcardShellShock from "../../images/cards/Techcard_Shell_Shock.png";
import gearTransponderHackDestroyer from "../../images/cards/Gear_Transponder_Hack_Destroyer.png";
import gearTransponderHackObliterator from "../../images/cards/Gear_Transponder_Hack_Obliterator.png";


function CardLayer() {
    const common = [gearCoolantCanister, gearLifeSupportSystems, unitFlytrap, unitLancer];
    const rare = [gearLongRangeComms, techcardLastCommands, unitCrucible, techcardShellShock];
    const epic = [gearFirewall, gearPowerCell, techcardSoreLoser, unitCloud];
    const legendary = [unitRogueEye, gearShockShells, gearTransponderHackDestroyer, gearTransponderHackObliterator];

    const randomImg = (e) => {
        const min = 0
        const max = 4
        return e[Math.floor(Math.random() * (max - min) + min)];
    }

    return (
        <div className={styles.main}>
            <div className={styles.background}> </div>
            <div className={styles.carouselBg}> </div>
            <div className={styles.wrap}>

                <div className={styles.titleWrap}>
                    <div className={styles.title}><FormattedMessage id="cards_title" /></div>
                    <img src={lineTitle} className={styles.borderImg} alt=""></img>
                </div>

                <div className={styles.cardLayer}>

                    <Card
                        title={<FormattedMessage id="cards_common" />}
                        text={<FormattedMessage id="cards_common_text" />}
                        color={"#40f0f0"}
                        cardBackImg={commonCard}
                        cardFrontImg={() => randomImg(common)}
                    />
                    <Card
                        title={<FormattedMessage id="cards_rare" />}
                        text={<FormattedMessage id="cards_rare_text" />}
                        color={"#e540ff"}
                        cardBackImg={rareCard}
                        cardFrontImg={() => randomImg(rare)}
                    />
                    <Card
                        title={<FormattedMessage id="cards_epic" />}
                        text={<FormattedMessage id="cards_epic_text" />}
                        color={"#fff940"}
                        cardBackImg={epicCard}
                        cardFrontImg={() => randomImg(epic)}
                    />
                    <Card
                        title={<FormattedMessage id="cards_special" />}
                        text={<FormattedMessage id="cards_special_text" />}
                        color={"#ff0000"}
                        cardBackImg={legendaryCard}
                        cardFrontImg={() => randomImg(legendary)}
                    />
                </div>

                <div className={styles.cardCarousel}>
                    <Carousel
                        showThumbs={false}
                        showArrows={false}
                        showStatus={false}
                        emulateTouch={true}
                        infiniteLoop={true}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (
                                    <CarouselPoint
                                        fill={"#0cf"}
                                        className={styles.indicatorStyles}
                                        aria-label={`Selected: ${label} ${index + 1}`}
                                        title={`Selected: ${label} ${index + 1}`}
                                    />
                                );
                            }
                            return (
                                <CarouselPoint
                                    fill={"#707070"}
                                    className={styles.indicatorStyles}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    title={`${label} ${index + 1}`}
                                    aria-label={`${label} ${index + 1}`}
                                />
                            );
                        }}
                    >
                        <div className={styles.carousel}>
                            <div className={styles.carouselDiv}>

                                <Card
                                    title={<FormattedMessage id="cards_common" />}
                                    text={<FormattedMessage id="cards_common_text" />}
                                    color={"#40f0f0"}
                                    cardBackImg={commonCard}
                                    cardFrontImg={() => randomImg(common)}
                                />
                            </div>
                        </div>
                        <div className={styles.carousel}>
                            <div className={styles.carouselDiv}>
                                <Card
                                    title={<FormattedMessage id="cards_rare" />}
                                    text={<FormattedMessage id="cards_rare_text" />}
                                    color={"#e540ff"}
                                    cardBackImg={rareCard}
                                    cardFrontImg={() => randomImg(rare)}
                                />
                            </div>
                        </div>
                        <div className={styles.carousel}>
                            <div className={styles.carouselDiv}>

                                <Card
                                    title={<FormattedMessage id="cards_epic" />}
                                    text={<FormattedMessage id="cards_epic_text" />}
                                    color={"#fff940"}
                                    cardBackImg={epicCard}
                                    cardFrontImg={() => randomImg(epic)}
                                />
                            </div>
                        </div>
                        <div className={styles.carousel}>
                            <div className={styles.carouselDiv}>

                                <Card
                                    title={<FormattedMessage id="cards_special" />}
                                    text={<FormattedMessage id="cards_special_text" />}
                                    color={"#ff0000"}
                                    cardBackImg={legendaryCard}
                                    cardFrontImg={() => randomImg(legendary)}
                                />
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default CardLayer;