import React from "react";

import styles from "./Heroes.module.scss";
import CarouselHero from "../../components/carouselHero/CarouselHero";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import lineTitle from "../../images/Images/line_titles.svg";
import { ReactComponent as CarouselPoint } from "../../images/Images/carousel_point.svg";
import { FormattedMessage } from "react-intl";
import MiloshImg from "../../images/heroes/Milosh.png";
import SekhmetImg from "../../images/heroes/Sekhmet.png";
import TilImg from "../../images/heroes/Til.png";
import YulImg from "../../images/heroes/Yul.png";
import ZephImg from "../../images/heroes/zeph.png";

function Heroes() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.background}/>
                <div className={styles.carouselFrame}/>

                <div className={styles.titleWrap}>
                        <div className={styles.title}><FormattedMessage id="heroes_title"/></div>
                        <img src={lineTitle} className={styles.borderImg} alt=""></img>
                </div>

                <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    emulateTouch={true}
                    infiniteLoop={true}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        if (isSelected) {
                            return (
                                <CarouselPoint
                                    fill={"#0cf"}
                                    className={styles.indicatorStyles}
                                    aria-label={`Selected: ${label} ${index + 1}`}
                                    title={`Selected: ${label} ${index + 1}`}
                                />
                            );
                        }
                        return (
                            <CarouselPoint
                                fill={"#707070"}
                                className={styles.indicatorStyles}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                title={`${label} ${index + 1}`}
                                aria-label={`${label} ${index + 1}`}
                            />
                        );
                    }}
                >
                    <div className={styles.carousel}>
                        <CarouselHero
                            heroName={<FormattedMessage id="heroes_Milosh_heroName"/>}
                            heroSubtitle={<FormattedMessage id="heroes_Milosh_heroSubtitle"/>}
                            text={<FormattedMessage id="heroes_Milosh_text"/>}
                            heroImg={MiloshImg}
                            />
                    </div>
                    <div className={styles.carousel}>
                        <CarouselHero
                            heroName={<FormattedMessage id="heroes_Zeph_heroName"/>}
                            heroSubtitle={<FormattedMessage id="heroes_Zeph_heroSubtitle"/>}
                            text={<FormattedMessage id="heroes_Zeph_text"/>}
                            heroImg={ZephImg}
                            />
                    </div>
                    <div className={styles.carousel}>
                        <CarouselHero
                            heroName={<FormattedMessage id="heroes_Yul_heroName"/>}
                            heroSubtitle={<FormattedMessage id="heroes_Yul_heroSubtitle"/>}
                            text={<FormattedMessage id="heroes_Yul_text"/>}
                            heroImg={YulImg}
                            />
                    </div>
                    <div className={styles.carousel}>
                        <CarouselHero
                            heroName={<FormattedMessage id="heroes_Sekhmet_heroName"/>}
                            heroSubtitle={<FormattedMessage id="heroes_Sekhmet_heroSubtitle"/>}
                            text={<FormattedMessage id="heroes_Sekhmet_text"/>}
                            heroImg={SekhmetImg}
                            />
                    </div>
                    <div className={styles.carousel}>
                        <CarouselHero
                            heroName={<FormattedMessage id="heroes_Til_heroName"/>}
                            heroSubtitle={<FormattedMessage id="heroes_Til_heroSubtitle"/>}
                            text={<FormattedMessage id="heroes_Til_text"/>}
                            heroImg={TilImg}
                            />
                    </div>
                </Carousel>
            </div>
        </div>
    );        
}

export default Heroes;