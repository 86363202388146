import React, { useEffect, useState } from "react";

import styles from "./GameContent.module.scss";
import ContentBox from "../../components/contentBox/ContentBox";
import chessboardImg from "../../images/Images/img_chessboard.png";
import chessboardBg from "../../images/Images/icon_arena.svg";
import tankImg from "../../images/Images/img_tanks.png";
import tankBg from "../../images/Images/icon_tanks.svg";
import dronesImg from "../../images/Images/img_drones.png";
import dronesBg from "../../images/Images/icon_drones.svg";
import mapsImg from "../../images/Images/img_arena.png";
import mapsBg from "../../images/Images/icon_buildings.svg";
import drone1 from "../../images/Images/img_drone1.png";
import drone2 from "../../images/Images/img_drone2.png";
import { FormattedMessage } from "react-intl";

function GameContent() {

    const [offSet, setOffset] = useState(0);

    useEffect(() => {
        function handleScroll() {
        setOffset(window.pageYOffset)
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        };
    }, [offSet]);

    return (
        <div className={styles.main}>
            <div className={styles.drones}>
                <img
                    src={drone1}
                    alt="drone"
                    className={styles.drone1}
                    style={{
                        top: `calc(${offSet * 0.2}px)`
                    }} 
                    />
                <img
                    src={drone2}
                    alt="drone2"
                    className={styles.drone2}
                    style={{
                        bottom: `calc(${offSet * 0.2 - 150}px)`
                    }} 
                    />
            </div>
            <div className={styles.wrap}>
                <ContentBox
                    title={<FormattedMessage id="gameContent_chessboard"/>}
                    text={<FormattedMessage id="gameContent_chessboard_text"/>}
                    reverseRow={false}
                    contentImg={chessboardImg}
                    textBg={chessboardBg}
                />

                <ContentBox
                    title={<FormattedMessage id="gameContent_tank"/>}
                    text={<FormattedMessage id="gameContent_tank_text"/>}
                    reverseRow={true}
                    contentImg={tankImg}
                    textBg={tankBg}
                />

                <ContentBox
                    title={<FormattedMessage id="gameContent_drones"/>}
                    text={<FormattedMessage id="gameContent_drones_text"/>}
                    reverseRow={false}
                    contentImg={dronesImg}
                    textBg={dronesBg}
                />

                <ContentBox
                    title={<FormattedMessage id="gameContent_maps"/>}
                    text={<FormattedMessage id="gameContent_maps_text"/>}
                    reverseRow={true}
                    contentImg={mapsImg}
                    textBg={mapsBg}
                />
            </div>
        </div>
    );        
}

export default GameContent;