import React from "react";

import styles from "./ContentBox.module.scss";
import SvgFrame from "../../images/Images/frame_images.svg";
import lineTitle from "../../images/Images/line_titles.svg";

function ContentBox({
    title,
    reverseRow,
    text,
    textBg,
    contentImg
    }) {

    return (
        <div className={reverseRow ? styles.boxReverse : styles.boxRow}>
            <div className={styles.textBox}>
                <img src={textBg} alt="background" className={styles.backgroundSvg}/>
                <div className={styles.title}>{title}</div>
                <img src={lineTitle} alt="border" className={styles.borderImg}/>
                <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.imageBox}>
                <img src={contentImg} alt="content" className={styles.contentImg}/>
                <img src={SvgFrame} alt="container" className={styles.blueContainerSvg}/>
            </div>
        </div>
    );        
}

export default ContentBox;