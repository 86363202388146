import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';

import styles from "./Topbar.module.scss";
import { FormattedMessage } from "react-intl";
import PopUp from "../popUp/PopUp"
import useOutsideClick from "../outsideClick/useOutsideClick";
import OprorPdf from "../../images/oprorWhitePaper.pdf";

// import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function Topbar() {

  const [isSeen, setSeen] = useState(false);
  const [isActive, setActive] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setActive(false);
  });
  const downloadPaper = () => {
    fetch(OprorPdf).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Opror.pdf';
          alink.click();
      })
  })}

    return (
        <div className={styles.main}>
            <div className={styles.fixedMain}>

              <div className={styles.wrap}>
                  <Link to={"/"} className={styles.logoWrap}>
                      <div className={styles.logo}>
                      </div>
                  </Link>

                  <div className={styles.navWrap}>
                    <Link to={"/media"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="media"/></span>
                    </Link>
                    {/*<Link to={"/news"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="news"/></span>
                    </Link>*/}
                    <Link to={"/aboutUs"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="aboutUs"/></span>
                    </Link>
                    <Link to={"/faq"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="faq"/></span>
                    </Link>
                    <Link to={"/furnace"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="furnace"/></span>
                    </Link>
                    <Link to={"/founders"} className={styles.squarelessBtn}>
                      <span><FormattedMessage id="founders"/></span>
                    </Link>
                    <div className={styles.squarelessBtn} onClick={() => downloadPaper()}>
                      <span><FormattedMessage id="whitepaper"/></span>
                    </div>  
                    {/*<div className={styles.wallet}><WalletMultiButton /></div>*/}
                    {/*<div className={styles.wallet}><WalletDisconnectButton /></div> */}
                    {/*
                    <div className={styles.squareBtn} onClick={() => setSeen(true)}>
                      <span><FormattedMessage id="topbar_downloadNow"/></span>
                    </div>
                    */}
                  </div>
              </div>
              <div className={styles.wrapMobile}>
                <div className={styles.containerMobile}>
                    <Link to={"/"} className={styles.logoWrap}>
                        <div className={styles.logo}>
                        </div>
                    </Link>
                    <div
                      className={styles.menuMobile}
                      onClick={() => setActive(!isActive)}
                      ref={ref}
                      >
                    </div >
                </div>
              </div>

            </div>
            {isActive ?
              <div className={styles.menuBox}>
                    <Link to={"/media"} className={styles.mobileBtn}>
                      <span><FormattedMessage id="media"/></span>
                    </Link>
                    {/*<Link to={"/news"} className={styles.mobileBtn}>
                      <span><FormattedMessage id="news"/></span>
                    </Link>
                    */}
                    <Link to={"/aboutUs"} className={styles.mobileBtn}>
                      <span><FormattedMessage id="aboutUs"/></span>
                    </Link>
                    <Link to={"/faq"} className={styles.mobileBtn}>
                      <span><FormattedMessage id="faq"/></span>
                    </Link>
                    <Link to={"/furnace"} className={styles.mobileBtn}>
                      <span><FormattedMessage id="furnace"/></span>
                    </Link>
                    <div className={styles.mobileBtn} onClick={() => downloadPaper()}>
                      <span><FormattedMessage id="whitepaper"/></span>
                    </div>  
                    {/*<div className={styles.walletMobile}><WalletMultiButton /></div>*/}
                    {/*<div className={styles.walletMobile}><WalletDisconnectButton /></div>*/}
                    {/*
                    <div className={styles.mobileBtnBlue} onClick={() => setSeen(true)}>
                      <span><FormattedMessage id="topbar_downloadNow"/></span>
                    </div>
                    */}
              </div>
            : null}
            {isSeen ?
                <PopUp
                    toggleClose={() => setSeen(!isSeen)}
                    /> :
                null}
        </div>
    );
}

export default Topbar;
