import React from "react";

import styles from "./PopUp.module.scss";
import { FormattedMessage } from "react-intl";

function PopUp({
    toggleClose,
    }) {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.logo}></div>
                <div className={styles.linearBg}>
                    <div className={styles.closeBtn} onClick={toggleClose}>X</div>
                    <div className={styles.textWrap}>
                        <div className={styles.title}><FormattedMessage id="popup_title" /></div>
                        <div className={styles.inputWrap}>
                            <div className={styles.emailBg}>
                                <input className={styles.email} type="text" placeholder="opror@patchnotes.com"></input>
                            </div>
                            <div>
                                <span className={styles.termText}><FormattedMessage id="popup_text_p1" /></span>
                                <span className={styles.termTextBlue}> <FormattedMessage id="popup_text_blue" /> </span>
                                <span className={styles.termText}><FormattedMessage id="popup_text_p3" /></span>
                            </div>
                        </div>
                        <div className={styles.suscribeBtn}><FormattedMessage id="popup_subscribe" /></div>
                    </div>
                </div>
            </div>
        </div>
    );        
}

export default PopUp;