import './App.css';
import React, { useState} from 'react';
import Landingscreen from "./screens/landingscreen/Landingscreen.js"
import { IntlProvider } from "react-intl";
import messagesEN from "./components/languajes/English"
import messagesDE from "./components/languajes/German"
import messagesES from "./components/languajes/Spanish"

function App() {

  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(messagesEN);

  const onChangeLanguage = (e) => {
    switch(e) {
      case "en":
        setLocale("en");
        setMessages(messagesEN);
        window.localStorage.setItem("language", "en");
        break;
      case "es":
        setLocale("es");
        setMessages(messagesES);
        window.localStorage.setItem("language", "es");
        break;
      case "de":
        setLocale("de");
        setMessages(messagesDE);
        window.localStorage.setItem("language", "de");
        break;
      default:
        setLocale("en");
        setMessages(messagesEN);
        window.localStorage.setItem("language", "en");
    }
  }

  return (
    <div>
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <Landingscreen onChangeLanguage={(e) => onChangeLanguage(e)}/>
      </IntlProvider>
    </div>
  );
}

export default App;
