import React, { useEffect, useState } from "react";

import styles from "./Media.module.scss";

import { FormattedMessage } from "react-intl";
import MediaBox from "../../components/mediaBox";
import drone1 from "../../images/Media/img_tank1_media.png";
import drone2 from "../../images/Media/img_tank2_media.png";
import drone3 from "../../images/Media/img_tank3_media.png";
import artImg1 from "../../images/Media/art_img1.png";
import artImg2 from "../../images/Media/art_img2.png";
import artImg3 from "../../images/Media/art_img3.png";
import artImg4 from "../../images/Media/art_img4.png";
import screenImg1 from "../../images/Media/screen_img1.png";
import screenImg2 from "../../images/Media/screen_img2.png";
import screenImg3 from "../../images/Media/screen_img3.png";
import screenImg4 from "../../images/Media/screen_img4.png";
import MediaCarousel from "../../components/mediaCarousel";


function Media() {

    const [offSet, setOffset] = useState(0);
    const [isArt, setArt] = useState(false);
    const [isScreenshot, setScreenshot] = useState(false);
    const [artSelector, setArtSelector] = useState(0);
    const [SreenshotSelector, setSreenshotSelector] = useState(0);

    useEffect(() => {
        function handleScroll() {
            setOffset(window.pageYOffset)
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        };
    }, [offSet]);

    function setArtHandler(e) {
        setArtSelector(e);
        setArt(true);
    }

    function setScreenshotHandler(e) {
        setSreenshotSelector(e);
        setScreenshot(true);
    }

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                {isArt
                    ? <div className={styles.MediaCarousel}>
                        <div className={styles.MediaCarouselWrap}>
                            <div className={styles.closeButton} onClick={() => setArt(false)}>x</div>
                            <MediaCarousel
                                imageSelector={artSelector}
                                image1={artImg1}
                                image2={artImg2}
                                image3={artImg3}
                                image4={artImg4}
                            />
                        </div>
                    </div>

                    : null
                }
                {isScreenshot
                    ? <div className={styles.MediaCarousel}>
                        <div className={styles.MediaCarouselWrap}>
                            <div className={styles.closeButton} onClick={() => setScreenshot(false)}>x</div>
                            <MediaCarousel
                                imageSelector={SreenshotSelector}
                                image1={screenImg1}
                                image2={screenImg2}
                                image3={screenImg3}
                                image4={screenImg4}
                            />
                        </div>
                    </div>

                    : null
                }
                <div className={styles.mediaWrap}>
                    <div className={styles.backgroundMedia}/>
                    <div className={styles.mediaTextBg}>
                        <FormattedMessage id="media" />
                    </div>
                    <div className={styles.droneTop}>
                        <img
                            src={drone1}
                            alt="drone"
                            className={styles.drone1}

                        />
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.contentWrap}>
                        <div className={styles.titleWrap}>
                            <div className={styles.title}><FormattedMessage id="media_art" /></div>
                            <div className={styles.underline}/>
                        </div>
                        <div className={styles.assetWrap}>
                            <MediaBox
                                contentImg={artImg1}
                                onClickHandler={(e) => setArtHandler(0)}
                            />
                            <MediaBox
                                reverseRow={true}
                                contentImg={artImg2}
                                onClickHandler={(e) => setArtHandler(1)}
                            />
                            <MediaBox
                                contentImg={artImg3}
                                onClickHandler={(e) => setArtHandler(2)}
                            />
                            <MediaBox
                                reverseRow={true}
                                contentImg={artImg4}
                                onClickHandler={(e) => setArtHandler(3)}
                            />
                        </div>
                    </div>

                    <div className={styles.contentWrap}>
                        <div className={styles.titleWrap}>
                            <div className={styles.title}><FormattedMessage id="media_sreenshots" /></div>
                            <div className={styles.underline}/>
                        </div>
                        <div className={styles.assetWrap}>
                            <MediaBox
                                contentImg={screenImg1}
                                onClickHandler={(e) => setScreenshotHandler(0)}
                            />
                            <MediaBox
                                reverseRow={true}
                                contentImg={screenImg2}
                                onClickHandler={(e) => setScreenshotHandler(1)}
                            />
                            <MediaBox
                                contentImg={screenImg3}
                                onClickHandler={(e) => setScreenshotHandler(2)}
                            />
                            <MediaBox
                                reverseRow={true}
                                contentImg={screenImg4}
                                onClickHandler={(e) => setScreenshotHandler(3)}
                            />
                            <div className={styles.droneMid}>
                                <img
                                    src={drone2}
                                    alt="drone"
                                    className={styles.drone1}

                                />
                            </div>
                            <div className={styles.droneBottom}>
                                <img
                                    src={drone3}
                                    alt="drone"
                                    className={styles.drone1}

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

export default Media;
