import React from "react";

import styles from "./CarouselHero.module.scss";

function CarouselHero( {
    heroName,
    heroSubtitle,
    text,
    heroImg
} ) {

    return (
        <div className={styles.main}>
            <div className={styles.heroImg}
                style={{ backgroundImage: `url(${heroImg})` }}
            />
            <div className={styles.carouselText}>
                <div className={styles.textWrap}>
                    <div className={styles.background}/>
                    <div className={styles.textBox}>
                        <div className={styles.heroName}>{heroName}</div>
                        <div className={styles.heroSubtitle}>{heroSubtitle}</div>
                        <div className={styles.heroText}>{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );        
}

export default CarouselHero;