const messagesEN = {
    // ABOUT US
    aboutUs_title: "ABOUT US",
    aboutUs_text_firstP: "Patch Notes is a small distributed game studio founded in 2017 in Berlin. We were gamers and have been dreaming of creating our own games long before we started our quest developing games in Patch Notes.",
    aboutUs_text_secondP: "Since 2019 we have been working on Oprør. As a company we believe in remote asynchronous communication. At least once a week timezones however do align and we socialise by playing and exploring newly launched games.",
    aboutUs_text_thirdP: "Contact:",

    // CARDS
    cards_title: "CARDS",
    cards_common: "Common",
    cards_common_text: "There are 3 different, interconnected Card Types: Units, Gear and Techs.",
    cards_rare: "Rare",
    cards_rare_text: "Grasp the power of the cards with a quick look.",
    cards_epic: "Epic",
    cards_epic_text: "Disrupt the battlefield with powerful magic-like technology",
    cards_special: "Legendary",
    cards_special_text: "Command your troops with mighty heroes.",

    // COMMON
    aboutUs: "About Us",
    download: "Newsletter",
    faq: "FAQ",
    news: "News",
    media: "Media",
    founders: "Founders",
    furnace: "Furnace",
    whitepaper: "White Paper",
    // DATENSCHUTZ

    datenschutz_title: "DATENSCHUTZ",
    datenschutz_text_firstP: "Patchnotes Ug is a small pationate game studio based in Berlin. We started our dance doing games in 2017. But long before that we were all gamers and dreamed of creating our games. Each new game we develop starts with the same question. Wouldn't it be awesome if a game like this existed?... ",
    datenschutz_text_secondP: "Our first game was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Oprør our card based auto-battler. As a company we believe in remote asynchronous communication. For us gaming it's not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely. ",

    // FAQS

    faqs_title: "FAQ",
    faqs_Q1_title: "What is Oprør?",
    faqs_Q1_text: "Oprør is a card base auto-chess game. Where players set up before the battle their own decks in order to beat the opponent.",

    faqs_Q2_title: "What's the difference between Oprør and the other auto-chess out there?",
    faqs_Q2_text: "We all love auto-chess, but at Patch Notes we though there is bit to much randomness, and a lack of tactics or control. That’s what brings our card system to the table.",
    faqs_Q2_text2: " In Oprør one sets multiple Decks and goes to a Match knowing beforehand what are your strengths and weaknesses. So you can think tactically, and wear a dramatic looking commander hat.",
    faqs_Q2_text3: " Also, we want people in Oprør to be able to play the style they prefer, not being forced to choose from what is randomly available at the beginning of each match.  Hope you guys like it!",

    faqs_Q3_title: "In what platform will Oprør be available?",
    faqs_Q3_text: "Our game will be at the beginning on PC and mobile.",

    faqs_Q4_title: "Will there be cross-platform play?",
    faqs_Q4_text: "Yes, from the moment we released the Android and iOS versions, cross-platform to other mobiles or pc player will be a reality.",

    faqs_Q5_title: "How much will Oprør cost?",
    faqs_Q5_text: "Oprør is a forever free to play game. Though you may purchase in-game cosmetics.",

    faqs_Q6_title: "Will I get any advantages if I pay money in the game?",
    faqs_Q6_text: "No. Well maybe that we will be able to afford food for dinner, yay!",

    faqs_Q7_title: "There will be loot boxes in Oprør?",
    faqs_Q7_text: "Yes, the card packs will deliver randomly 5 card to the players, but there are other other ways to get a specific desired card.",

    faqs_Q8_title: "What types of cards are there?",
    faqs_Q8_text: "There are three, units, Gear and Tech cards.",

    faqs_Q9_title: "Can I only buy skins with real money?",
    faqs_Q9_text: "Not at all! You can also unlock any relevant and cool skin with in game currency, which is obtainable as rewards for battles and missions.",

    faqs_Q10_title: "Will there be events and special offers?",
    faqs_Q10_text: "Absolutely, some skins and aesthetics will be available only for special occasions.",

    faqs_Q11_title: "Can I play with friends?",
    faqs_Q11_text: "Well, yes. You just need to have friends.",

    faqs_Q12_title: "In what languages will Oprør be released?",
    faqs_Q12_text: "At the beginning in English and maybe will put other languages like Spanish or German.",

    faqs_Q13_title: "Is Oprør only multiplayer or there is also a single player mode?",
    faqs_Q13_text: "At the beginning we will release the multiplayer mode, but we are secretly working on single player mode… Or maybe not so secretly.",

    // FOOTER
    impressum: "Impressum",
    datenschutz: "Datenschutz",

    // GAME CONTENT
    gameContent_chessboard: "BATTLEBOARD.",
    gameContent_chessboard_text: "Plan your strategy, think ahead of your opponents to overthrow your rival!",
    gameContent_tank: "TANKS",
    gameContent_tank_text: "Upgrade your units, level them up or use any possible mean to turn your cute little car into a death-evoking machine.",
    gameContent_drones: "D.R.O.N.E.S.",
    gameContent_drones_text: "War is always entertaining... but so is tourism! Fly around between your own matches or when watching a friend die horribly! Enjoy the view with this fancy looking free-flying drones!",
    gameContent_maps: "MAPS",
    gameContent_maps_text: "To win a battle one must know the terrain... or just make it more pretty. Either works. Edit your battlefield and map to accommodate to your tastes.",

    // HEROES
    heroes_title: "HEROES",
    heroes_Milosh_heroName: "MILOSH",
    heroes_Milosh_heroSubtitle: "The Space Zombie",
    heroes_Milosh_text: "After a bizarre accident involving Orgoth and insane amounts of vodka, Milosh woke up in a flagship’s sickbay feeling weird.  He was dead, but not quite. Proclaimed dead for the records, but still able to talk, move… and command. He just needs to stay inside his “unlife” support suit. His new condition gave him a special affinity and connection with the Void, granting him powers of control over tainted units by the Anathema.",

    heroes_Zeph_heroName: "ZEPH",
    heroes_Zeph_heroSubtitle: "The Merc",
    heroes_Zeph_text: "Trying to remember a life before the first “Event” is meaningless, so is trying to run away from war. Zeph learned this in the hardest of ways after his home planet was wiped out by the Anathema. Joining the SPQRO as a soldier of fortune was a no brainer for him after that, reaching the level of “Second Unit Commander” in no time. Though his allegiance lies with his paycheck, his uttermost desire is to fight the Anathema until the last man… even if it means literally that.    ",

    heroes_Yul_heroName: "YUL",
    heroes_Yul_heroSubtitle: "The Sheriff",
    heroes_Yul_text: "There've been cases of 'Tamarak individuals' where these sentient machines break the link with the Motherhive and thrive into different sectors of society. This is the case of Yul, the Tamarak Sheriff of Terra Sector. For decades he became the icon of law and order, and after the last Anomaly Event he took the career of Commanding Officer. Despite his condition of 'Outcast', tamarak units will accept to follow his commands in battle.",

    heroes_Sekhmet_heroName: "SEKHMET",
    heroes_Sekhmet_heroSubtitle: "The Assassin",
    heroes_Sekhmet_text: "The karathi are one of the most enigmatic races that came through the Rifts, highly belligerent, yet with strict codes of conduct and honor. Some of their clans were known for being secretive and treacherous. From one of these clans Sekhmet emerged. She quickly became one of the best assassins of the SPQRO, and led many Dark Operations. She applied for the position of Legionare Commander, and in no time she excelled in combat as a careful, relentless strategist.",

    heroes_Til_heroName: "TIL",
    heroes_Til_heroSubtitle: "The Tank",
    heroes_Til_text: "Strong, bad tempered, short-fused and with an irrational hate for the Anathema, Til became a commander with only one idea: to crush every single last one of them, or die trying. His hate for the Void is only measurable against his fear for vegetable based food, which for him is considered the worst torture in this universe. He personally oversees the works on his units to make them stronger in order to sustain more damage when he sends them charging towards the front line.",

    // IMPRESSUM
    impressum_title: "IMPRESSUM",
    impressum_text_firstP: "Patchnotes Ug is a small passionate game studio based in Berlin. We started our dance doing games in 2017. But long before that we were all gamers and dreamed of creating our games. Each new game we develop starts with the same question. Wouldn't it be awesome if game like this existed?... ",
    impressum_text_secondP: "Our first game was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Oprør our card based auto-battler. As a company we believe in remote asynchronous communication. For us gaming it's not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely. ",

    // MEDIA
    media_art: "ART",
    media_sreenshots: "SCREENSHOTS",

    // NEWS
    news_titleBg: "NEWS!",
    news_title: "RECENT NEWS",

    // OPROAR
    opror_title: "The battle is close!",
    opror_text: "Plan, adapt, overcome. Oprør is a tactical card based auto-battler, where luck alone won't save you. Choose a hero, prepare a deck, send your troops to the board, and see heroes sacrifice for glory, peace or vengance.",

    // POP UP
    popup_title: "Subscribe to our newsletter!",
    popup_text_p1: "I have read and accept the",
    popup_text_blue: "Terms and Conditions",
    popup_text_p3: "of PatchNotes",
    popup_subscribe: "Subscribe",

    // TOPBAR
    topbar_downloadNow: "Newsletter",

    // NOTES PAGES

    notesPages_title: "CARDS",
    notesPages_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volo in lecto jacebat pupillam oculi.",
    notesPages_text_2: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur",

    // WHITE NIGHTS

    whiteNight_text_p1: "Thanks for connecting with us at the",
    whiteNight_text_p2: "Gamescom 2022",
    whiteNight_text_p3: " ",
    whiteNight_text_p4: "Add your contact details below - We’d love to stay in touch and send you updates once in a while",
    whiteNight_text_name: "Name",
    whiteNight_text_email: "E-mail",
    whiteNight_submit: "Submit",
    whiteNight_popupSubscribe_text: "Submitted! Thank you for contacting us!"
}

export default messagesEN;
