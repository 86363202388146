import React from "react";
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import styles from "./Landingscreen.module.scss";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
// import AssetView from "../assetView/AssetView";
import Media from "../media";
import AboutUs from "../aboutUs";
import Impressum from "../impressum";
import Datenschutz from "../datenschutz";
import Faq from "../faq";
import News from "../news";
import NewsNotes from "../newsNotes";
import WhiteNight from "../whiteNight";
import NftMint from "../nftMint";
import FirstScreen from "../firstscreen";
import ErrorPage from "../errorpage";
import Furnace from "../furnace";

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { SolflareWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
require('@solana/wallet-adapter-react-ui/styles.css');


function Landingscreen({ onChangeLanguage }) {

  const network = WalletAdapterNetwork.Devnet;
  // You can also provide a custom RPC endpoint.
  const endpoint = clusterApiUrl(network);
  const wallets = [new SolflareWalletAdapter(), new PhantomWalletAdapter()];

  return (
    <div className={styles.main} id={"Landingscreen"}>

            <BrowserRouter>
              <div className={styles.topbar}>
                <Topbar />
              </div>

              <div className={styles.router}>
                <Routes>
                  <Route exact path="/" element=<FirstScreen /> />

                  <Route exact path="/conventions" element=<WhiteNight /> />
                  <Route path="/article/:articleId" element=<NewsNotes /> />
                  <Route exact path="/news" element=<News /> />
                  <Route exact path="/media" element=<Media /> />
                  <Route exact path="/aboutUs" element=<AboutUs /> />
                  <Route exact path="/impressum" element=<Impressum /> />
                  <Route exact path="/datenschutz" element=<Datenschutz /> />
                  <Route exact path="/errorpage" element=<ErrorPage /> />
                  <Route exact path="/furnace" element=<Furnace /> />
                  <Route path="/founders" element=<React.Fragment>
                    <ConnectionProvider endpoint={endpoint}>
                      <WalletProvider wallets={wallets} autoConnect>
                        <WalletModalProvider>
                          <NftMint />
                        </WalletModalProvider>
                      </WalletProvider>
                    </ConnectionProvider>
                  </React.Fragment> />
                  <Route exact path="/faq" element=<Faq /> />
                </Routes>
              </div>
              <div className={styles.footer}>
                <Footer onChangeLanguage={(e) => onChangeLanguage(e)} />
              </div>
            </BrowserRouter>

    </div>
  );
}

export default Landingscreen;
