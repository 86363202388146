import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';

import styles from "./NewsNotes.module.scss";
// import NewsBox from "../../components/newsBox/NewsBox";
import NotesPages from "../../components/notesPages/NotesPages";

function NewsNotes() {
    const [article, setArticle] = useState()
    const { articleId } = useParams();

    useEffect(() => {
        axios.get(`/articles/${articleId}`).then(r => {
            setArticle(r.data)
        });
    }, [articleId]);

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.skullWrap}>
                    <div className={styles.skullBg}></div>
                </div>
                <div className={styles.backgroundStyle}></div>
                <div className={styles.headerWrap}>
                    <div className={styles.headerBackground}></div>
                    <div className={styles.headerFilter}></div>
                </div>

                <div className={styles.titleContainer}>
                    <div className={styles.arrowBack}></div>

                    <div className={styles.titleWrap}>
                        <div className={styles.subtext}>{article?.Subtitle}</div>
                        <div className={styles.title}>{article?.Title}</div>
                        <div className={styles.date}>{article?.Date}</div>
                    </div>

                    <div className={styles.arrowNext}></div>
                </div>

                <div className={styles.infoContainer}>
                    <div className={styles.infoNotes}>
                        <NotesPages data={article} />
                    </div>
                    <div className={styles.recentNotes}>
                        {/*   maybe for later?
                        <div className={styles.recentNews}><FormattedMessage id="news_title"/></div>

                        <NewsBox
                            mainNew={false}
                            title={"Patch Notes 1.0"}
                            text={"Lorem ipsum dolor sit amet. Ego amo te, mea puella oculos et consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. "}
                            date={"20-04-2021"}
                            contentImg={placeholder}
                            link={"/newNotes"}
                        />

                        <NewsBox
                            mainNew={false}
                            title={"Patch Notes 1.0"}
                            text={"Lorem ipsum dolor sit amet. Ego amo te, mea puella oculos et consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. "}
                            date={"20-04-2021"}
                            contentImg={placeholder}
                            link={"/newNotes"}
                        />
                    */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsNotes;
