import React from "react";

import styles from "./Faq.module.scss";
import { FormattedMessage } from "react-intl";
import FaqBox from "../../components/faqBox/FaqBox";

function Faq() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.logoBg} />
                <div className={styles.container}>
                    <div className={styles.title}><FormattedMessage id="faqs_title" /></div>
                    <div className={styles.textBox}>
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q1_title" />}
                            text={<FormattedMessage id="faqs_Q1_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q2_title" />}
                            text={<FormattedMessage id="faqs_Q2_text" />}
                            text2={<FormattedMessage id="faqs_Q2_text2" />}
                            text3={<FormattedMessage id="faqs_Q2_text3" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q3_title" />}
                            text={<FormattedMessage id="faqs_Q3_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q4_title" />}
                            text={<FormattedMessage id="faqs_Q4_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q5_title" />}
                            text={<FormattedMessage id="faqs_Q5_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q6_title" />}
                            text={<FormattedMessage id="faqs_Q6_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q7_title" />}
                            text={<FormattedMessage id="faqs_Q7_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q8_title" />}
                            text={<FormattedMessage id="faqs_Q8_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q9_title" />}
                            text={<FormattedMessage id="faqs_Q9_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q10_title" />}
                            text={<FormattedMessage id="faqs_Q10_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q11_title" />}
                            text={<FormattedMessage id="faqs_Q11_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q12_title" />}
                            text={<FormattedMessage id="faqs_Q12_text" />}
                        />
                        <FaqBox
                            title={<FormattedMessage id="faqs_Q13_title" />}
                            text={<FormattedMessage id="faqs_Q13_text" />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
