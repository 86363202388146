import React from "react";

import styles from "./AboutUs.module.scss";
import { FormattedMessage } from "react-intl";

function AboutUs() {

    return (
        <div className={styles.main}>
            <div className={styles.wrap}>
                <div className={styles.logoBg}/>
                <div className={styles.container}>
                    <div className={styles.logoWrap}/>
                    <div className={styles.textWrap}>
                            <div className={styles.title}><FormattedMessage id="aboutUs_title"/></div>
                            <div className={styles.text}>
                                <p><FormattedMessage id="aboutUs_text_firstP"/></p>
                                <p><FormattedMessage id="aboutUs_text_secondP"/></p>
                                <p><FormattedMessage id="aboutUs_text_thirdP"/> <a href="mailto:info@patchnotes.com">info@patchnotes.com</a></p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );        
}

export default AboutUs;