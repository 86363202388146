const messagesES = {
    // ABOUT US
    aboutUs_title: "ABOUT US",
    aboutUs_text_firstP: "Patchnotes Ug is a small pationate game studio based on Berlin. We started our dance doing games in 2017. But long before that we were all gamers and dreamed of creating our games. Each new game we develop starts with the same question. Wouldn't be awesome that a game like this existed?... ",
    aboutUs_text_secondP: "Our first fame was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Opror our card based auto-battler. As a company we believed in remote asynchronous communication. For us gaming is not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely. ",

    // CARDS
    cards_title: "CARDS",
    cards_common: "Common",
    cards_common_text: "There are 3 different card types, trap, unit, spell all interconnected.",
    cards_rare: "Rare",
    cards_rare_text: "Grasp the power of the cards by a quick look.",
    cards_epic: "Epic",
    cards_epic_text: "Disrupt the battlefield with powerful magic.",
    cards_special: "Special",
    cards_special_text: "Command your troops with mighty heroes cards.",

    // COMMON
    aboutUs: "About Us",
    download: "Download",
    faq: "FAQ",
    news: "News",

    // DATENSCHUTZ

    datenschutz_title: "DATENSCHUTZ",
    datenschutz_text_firstP: "Patchnotes Ug is a small pationate game studio based on Berlin. We started our dance doing games in 2017. But long before that we were all gamers and dreamed of creating our games. Each new game we develop starts with the same question. Wouldn't be awesome that a game like this existed?... ",
    datenschutz_text_secondP: "Our first fame was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Opror our card based auto-battler. As a company we believed in remote asynchronous communication. For us gaming is not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely. ",

    // FAQS

    faqs_title: "FAQ",
    faqs_firstBox_title: "Wouldn't be awesome that a game like this existed?...",
    faqs_firstBox_text: "Our first fame was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Opror our card based auto-battler. As a company we believed in remote asynchronous communication. For us gaming is not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely.",

    // FOOTER
    impressum: "Impressum",
    datenschutz: "Datenschutz",

    // GAME CONTENT
    gameContent_chessboard: "CHESSBOARD",
    gameContent_chessboard_text: "Plan your strategy, think ahead of the rest to overthrow your rival!",
    gameContent_tank: "TANKS",
    gameContent_tank_text: "Upgrade your units, level them up or use any posible mean to turn your cute little car into a death evoking machine.",
    gameContent_drones: "DRONES",
    gameContent_drones_text: "War is always entertaining... but so is tourism! Fly around between your own matches or when watching a friend die horribly! Enjoy the view with this fancy looking free fly drones!",
    gameContent_maps: "MAPS",
    gameContent_maps_text: "To win a battle one must know the terrain... or just make it more pretty. Both works. Edit your battlefield and map to accommodate to your tastes.",

    // HEROES
    heroes_title: "HEROES",
    heroes_milosh_heroName: "MILOSH",
    heroes_milosh_heroSubtitle: "The Space Zombie",
    heroes_milosh_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc aliquet bibendum enim facilisis gravida neque convallis a. Volo in lecto jacebat pupillam oculi.",

    // IMPRESSUM
    impressum_title: "IMPRESSUM",
    impressum_text_firstP: "Patchnotes Ug is a small pationate game studio based on Berlin. We started our dance doing games in 2017. But long before that we were all gamers and dreamed of creating our games. Each new game we develop starts with the same question. Wouldn't be awesome that a game like this existed?... ",
    impressum_text_secondP: "Our first fame was Nullermand.io, a simple cute looking top-down shooter game. And since 2019 we have been working on Opror our card based auto-battler. As a company we believed in remote asynchronous communication. For us gaming is not wasting time, is growing. Each Friday we get together to play games and p3wnd others... rarely. ",

    // NEWS
    news_titleBg: "NEWS!",
    news_title: "RECENT NEWS",


    // OPROAR
    oproar_title: "Call to Action",
    oproar_text: "Plan, adapt, overcome. Opror is tactical card base auto-battler, where luck alone won't save you. Choose a hero, prepare a deck, send your troops to table, and see heroes sacrifices for glory, peace or vengance.",

    // POP UP
    popup_title: "Subscribe to our newsletter!",
    popup_text_p1: "I have read and accept the",
    popup_text_blue: "Terms and Conditions",
    popup_text_p3: "of PatchNotes",
    popup_subscribe: "Subscribe",

    // TOPBAR
    topbar_downloadNow: "Download Now",

    // NOTES PAGES

    notesPages_title: "CARDS",
    notesPages_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volo in lecto jacebat pupillam oculi.",
    notesPages_text_2: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur"
}

export default messagesES;